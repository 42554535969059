import {Link} from "gatsby";
import React from "react";

const NoticeCard = ({title, excerpt, slug, author, date, tags}) => (
  <div className="my-1 px-1 w-full lg:w-1/2 md:my-4 md:px-4">
    <Link to={`${slug}`}><article className="overflow-hidden rounded-lg shadow-lg">

        <header className={"py-2 px-4 border-b-1 border-gray-300"}>
          <h2 className={"font-bold text-xl"}>{title}</h2>
        </header>

      <main className={"p-4 text-gray-700"}>
        {excerpt && <div className="p-2" dangerouslySetInnerHTML={{__html: excerpt}}></div>}
        {!excerpt && <div><i>There is no excerpt for this notice</i></div>}
      </main>
      <footer className={"py-2 px-4 border-t-1 border-gray-300 flex justify-between"}>
        <div className={"flex flex-col"}>
          <span className={"text-xs text-gray-700"}>{(new Date(date)).toLocaleString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })} by {author}</span>
          <span className={"text-xs text-gray-700"}>{tags && tags.map(({name}) => name).join(", ")}</span>
        </div>
        {/*<Link to={`${slug}`} className="text-sm">Read full <i className="fal fa-angle-double-right"></i></Link>*/}
      </footer>
    </article></Link>
  </div>
)

export default NoticeCard
