import React from 'react'
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import SEO from "../components/seo";
import NoticeCard from "../components/notice_card"
import TagSection from '../components/tag_section'

const Information = ({data}) => {
  const noticeList = data.notices.edges
  //const tagList = tags.nodes
  
    return (
        <Layout headerClass="bg-white relative">
          <SEO title="Notices and Information" />
          <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
            <div className="flex flex-col w-full">
              <div className="markdown-body mt-6">
                <h1>Notices &amp; Local Information</h1>
              </div>
              <div className="flex-shrink-0 py-0 flex flex-col md:flex-row mt-4">
                <div className="w-full md:w-3/5 lg:w-3/4">
                  {noticeList && noticeList.length === 0 &&
                  (<p><b>There are currently no notices</b></p>)
                  }
                  <div className="flex flex-wrap -mx-1 lg:-mx-4">
                    {
                      noticeList
                      && noticeList.map(({node: {excerpt, frontmatter: {title, date, path, author}}}) => (//{title, dateGmt, slug, excerpt, tags: {nodes: tags}, author: {node: {name}}}}) => (
                        <NoticeCard title={title} slug={path} excerpt={excerpt} date={date} author={author} key={path}/>
                      ))
                    }
                  </div>
                </div>
                {/*<div className="w-full md:w-2/5 lg:w-1/4 md:pl-6 text-right p-4 z-40 mx-auto md:ml-6 my-4 md:my-0 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse">*/}
                {/*  <h3 className={"font-bold text-2xl border-b-1 my-2 pb-2"}>By Category</h3>*/}
                {/*  /!*{tagList && tagList.map(({name, posts: {nodes: postList}}) => (*!/*/}
                {/*  /!*    <TagSection name={name} posts={postList} />*!/*/}
                {/*  /!*  ))}*!/*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </Layout>
    )
            {/*
            <div className="flex-shrink-0 py-0 flex flex-row mt-6">
              <div className="container my-12 mx-auto px-4 md:px-12">
                {noticeList && noticeList.length === 0 &&
                 (<p><b>There are currently no information</b></p>)
                }
                <div className="flex flex-wrap -mx-1 lg:-mx-4">
                {
                  noticeList && noticeList.length !== 0
                  && noticeList.map(({node: {title, dateGmt, slug, excerpt, author: {node: {name}}}}) => (
                    <Notice title={title} date={dateGmt} slug={slug} excerpt={excerpt} author={name} key={slug} />
                  ))
                }
                </div>
                
                 <div className="flex flex-wrap -mx-1 lg:-mx-4">
                  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img alt="Placeholder" className="block h-auto w-full"
                             src="https://picsum.photos/600/400/?random" />
                      </a>
                      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-lg">
                          <a className="no-underline hover:underline text-black" href="#">
                            Event Title
                          </a>
                        </h1>
                        <p className="text-grey-darker text-sm">
                          11/1/19
                        </p>
                      </header>
                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <a className="flex items-center no-underline hover:underline text-black" href="#">
                          <img alt="Placeholder" className="block rounded-full"
                               src="https://picsum.photos/32/32/?random" />
                            <p className="ml-2 text-sm">
                              Organiser
                            </p>
                        </a>
                        <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                          <span className="hidden">Like</span>
                          <i className="fa fa-heart"></i>
                        </a>
                      </footer>
                    </article>
                  </div>
                  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img alt="Placeholder" className="block h-auto w-full"
                             src="https://picsum.photos/600/400/?random" />
                      </a>
                      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-lg">
                          <a className="no-underline hover:underline text-black" href="#">
                            Event Title
                          </a>
                        </h1>
                        <p className="text-grey-darker text-sm">
                          11/1/19
                        </p>
                      </header>
                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <a className="flex items-center no-underline hover:underline text-black" href="#">
                          <img alt="Placeholder" className="block rounded-full"
                               src="https://picsum.photos/32/32/?random" />
                            <p className="ml-2 text-sm">
                              Organiser
                            </p>
                        </a>
                        <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                          <span className="hidden">Like</span>
                          <i className="fa fa-heart"></i>
                        </a>
                      </footer>
                    </article>
                  </div>
                  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img alt="Placeholder" className="block h-auto w-full"
                             src="https://picsum.photos/600/400/?random" />
                      </a>
                      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-lg">
                          <a className="no-underline hover:underline text-black" href="#">
                            Event Title
                          </a>
                        </h1>
                        <p className="text-grey-darker text-sm">
                          11/1/19
                        </p>
                      </header>
                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <a className="flex items-center no-underline hover:underline text-black" href="#">
                          <img alt="Placeholder" className="block rounded-full"
                               src="https://picsum.photos/32/32/?random" />
                            <p className="ml-2 text-sm">
                              Organiser
                            </p>
                        </a>
                        <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                          <span className="hidden">Like</span>
                          <i className="fa fa-heart"></i>
                        </a>
                      </footer>
                    </article>
                  </div>
                  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img alt="Placeholder" className="block h-auto w-full"
                             src="https://picsum.photos/600/400/?random" />
                      </a>
                      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-lg">
                          <a className="no-underline hover:underline text-black" href="#">
                            Event Title
                          </a>
                        </h1>
                        <p className="text-grey-darker text-sm">
                          11/1/19
                        </p>
                      </header>
                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <a className="flex items-center no-underline hover:underline text-black" href="#">
                          <img alt="Placeholder" className="block rounded-full"
                               src="https://picsum.photos/32/32/?random" />
                          <p className="ml-2 text-sm">
                            Organiser
                          </p>
                        </a>
                        <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                          <span className="hidden">Like</span>
                          <i className="fa fa-heart"></i>
                        </a>
                      </footer>
                    </article>
                  </div>
                  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img alt="Placeholder" className="block h-auto w-full"
                             src="https://picsum.photos/600/400/?random" />
                      </a>
                      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-lg">
                          <a className="no-underline hover:underline text-black" href="#">
                            Event Title
                          </a>
                        </h1>
                        <p className="text-grey-darker text-sm">
                          11/1/19
                        </p>
                      </header>
                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <a className="flex items-center no-underline hover:underline text-black" href="#">
                          <img alt="Placeholder" className="block rounded-full"
                               src="https://picsum.photos/32/32/?random" />
                          <p className="ml-2 text-sm">
                            Organiser
                          </p>
                        </a>
                        <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                          <span className="hidden">Like</span>
                          <i className="fa fa-heart"></i>
                        </a>
                      </footer>
                    </article>
                  </div>
                  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                    <article className="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img alt="Placeholder" className="block h-auto w-full"
                             src="https://picsum.photos/600/400/?random" />
                      </a>
                      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 className="text-lg">
                          <a className="no-underline hover:underline text-black" href="#">
                            Event Title
                          </a>
                        </h1>
                        <p className="text-grey-darker text-sm">
                          11/1/19
                        </p>
                      </header>
                      <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                        <a className="flex items-center no-underline hover:underline text-black" href="#">
                          <img alt="Placeholder" className="block rounded-full"
                               src="https://picsum.photos/32/32/?random" />
                          <p className="ml-2 text-sm">
                            Organiser
                          </p>
                        </a>
                        <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                          <span className="hidden">Like</span>
                          <i className="fa fa-heart"></i>
                        </a>
                      </footer>
                    </article>
                  </div>
                </div>
              </div>
            </div>*/}
}

export default Information

export const query = graphql`
    query {
        notices: allMdx(
            filter: { internal: { contentFilePath: { regex: "/content/information/"}}}
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        date
                        path
                        author
                    }
                    excerpt(pruneLength: 160)
                }
            }
        }
    }
`

// export const query = graphql`
//     query {
//         information: allWpPost(sort: {order: DESC, fields: date}, filter: {categories: {nodes: {elemMatch: {name: {regex: "", glob: "notice"}}}}}) {
//             edges {
//                 node {
//                     title
//                     dateGmt
//                     slug
//                     excerpt
//                     author {
//                         node {
//                             name
//                         }
//                     }
//                     tags {
//                         nodes {
//                             name
//                         }
//                     }
//                 }
//             }
//         }
//         tags: allWpTag(filter: {posts: {nodes: {elemMatch: {categories: {nodes: {elemMatch: {name: {eq: "notice"}}}}}}}}) {
//             nodes {
//                 name
//                 posts {
//                     nodes {
//                         title
//                         slug
//                     }
//                 }
//             }
//         }
//     }
// `;
